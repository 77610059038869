import { createRouter, createWebHashHistory } from 'vue-router'
import PCIndex from '@/pages/pc/index/index.vue'
import GoodsDetail from '@/pages/pc/goods-detail/GoodsDetail.vue'
import Classify from '@/pages/pc/classify/classify.vue'
import Company from '@/pages/pc/company/company.vue'

const pcRouter = () =>{
  return createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        path: '/',
        component: PCIndex
      },
      {
        path: '/Detail',
        component: GoodsDetail
      },
      {
        path: '/Classify',
        component: Classify
      },
      {
        path: '/Company',
        component: Company
      }
    ]
  })
}

export default pcRouter