<template>
  <header>
    <div class="header-container">
      <div class="header-topbar">
        <div class="header-topbar-container">
          <div class="left">
            您好！欢迎来到瑞葆堂！
          </div>
          <div class="right">
            <span>
              联系电话
            </span>
            <div class="tel">
              13868723138
            </div>
          </div>
        </div>
      </div>
      <div class="header-main">
        <el-row>
          <el-col class="left" :sm="7">
            <div class="left">
              <a href="/">
                <div class="logo-box">
                  <!-- <img src="../../assets/4.png" alt=""> -->
                  <van-image width="100%" height="100%" :src="require('../../assets/page_logo.png')" />
                </div>
              </a>
            </div>
          </el-col>
          <el-col class="left" :sm="1"></el-col>
          <el-col class="left" :sm="16">
            <div class="right">
              <div class="pc-search-box">
                <input v-model="str" type="text" class="pc-search-input" @keypress="onKeyPress">
                <button @click="onSearch"><van-icon name="search" style="margin-right: 8px"/>搜索</button>
              </div>
              <div class="qrcode-box hidden-xs-only">
                <van-image
                  width="100"
                  height="62"
                  fit="scale-down"
                  :src="require('../../assets/qrcode.png')"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="header-nav">
        <el-row>
          <!-- <el-col class="left" :sm="8">
          </el-col> -->
          <el-col class="left" :sm="24">
            <ul class="display:flex;">
              <li class="nav-item">
                <a class="all-foods" href="/#/Classify?category_id=0">
                  所有商品
                </a>
                <div class="float-menu">
                  <div class="float-menu-item" v-for="(item,index) in categoryList" :key="index">
                    <a :href="'/#/Classify?category_id=' + item.id + '&category_name=' + item.name" target="_blank">
                      <div class="img">
                        <img :src="item.icon" alt="" />
                      </div>
                      <div class="category">{{ item.name }}</div>
                    </a>
                  </div>
                </div>
              </li>
              <li class="nav-item">
                <a class="all-foods" href="/#/">
                  首页
                </a>
              </li>
              <li v-for="(item, index) in categoryList1" :key="index" style="flex:1;">
                <a :href="'/#/Classify?category_id=' + item.id + '&category_name=' + item.name">
                  {{ item.name }}
                </a>
              </li>
              <li class="nav-item">
                <a class="all-foods" href="/#/Company?category_id=0">
                  关于我们
                </a>
              </li>
              <!-- <li>
                <a href="#">
                  最新活动
                </a>
              </li>
              <li>
                <a href="#">
                  OTC药品
                </a>
              </li>
              <li>
                <a href="#">
                  器械专区
                </a>
              </li>
              <li>
                <a href="#">
                  品牌馆
                </a>
              </li> -->
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="header-footer-border"></div>
    </div>
  </header>
</template>

<script>
import 'element-plus/lib/theme-chalk/display.css'
import { getCategoryList } from '@/service/global'
export default {
  name: 'CommonHeader',
  props: {
    searchStr: {
      type: String,
      default: ''
    }
  },
  watch: {
    searchStr (news) {
      this.str = news
    }
  },
  data () {
    return {
      str: '',
      categoryList: [],
      categoryList1: []
    }
  },
  created () {
    this.str = this.searchStr
    getCategoryList().then(res => {
      this.categoryList1 = res.slice(0, 3)
      this.categoryList = res
    })
  },
  methods: {
    onKeyPress (ev) {
      if (ev.keyCode === 13) {
        this.onSearch()
      }
    },
    onSearch () {
      this.$router.push({
        path: '/Classify',
        query: {
          keyword: this.str
        }
      })
      this.$emit('search', this.str)
    }
  }
}
</script>

<style lang="scss" scoped>
header{
  width: 100%;
  .header-topbar{
    background-color: #f2f2f2;
    width: 100%;
    @media screen and(max-width: 1200px) {
      display: none;
    }
    .header-topbar-container{
      padding:0 10px;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      line-height: 30px;
      color: #777777;
      font-size: 12px;
    }
    .right{
      display: flex;
      .tel{
        margin-left: 16px;
      }
    }
  }
  .header-main{
    padding:0 10px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    .left{
      //width: 27%;
      .logo-box{
        cursor: pointer;
      }
    }
    .right{
      //margin-left: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      .pc-search-box{
        flex: 1;
        //margin-right: 64px;
        display: flex;
        .pc-search-input{
          width: 100%;
          height: 36px;
          border: 2px #00923f solid;
          text-indent: 10px;
          outline: none;
        }
        button{
          cursor: pointer;
          width: 108px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #00923f;
          border: none;
          outline: none;
          user-select: none;
          color: white;
        }
      }
      .qrcode-box{
        height: 62px;
      }
    }
  }
  .header-nav{
    max-width: 1200px;
    margin: 0 auto;
    overflow: visible;
    ul {
      margin-top: 1rem;
      list-style: none;
      padding: 0;
      display: flex;
      li{
        color: white;
        text-align: center;
        flex: 1;
        font-size: 18px;
        color: #333;
        position: relative;
        display: inline-block;
        .float-menu{
          display: none;
          z-index: 99;
          position: absolute;
          top: 100%;
          margin-top: 2px;
          left: 0;
          width: 100%;
          background-color: #94D2BD;
          padding: 1rem 0;
          .float-menu-item{
            a{
              width: 100%;
              padding: .5rem 2rem;
              display: flex;
              align-items: center;
              color: white;
              cursor: pointer;
              .img{
                height: 32px;
                margin-right: 1rem;
                img{
                  width: 32px;
                  height: 32px;
                }
              }
              .category{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                text-align: center;
                height: 32px;
                line-height: 32px;
              }
            }
          }
          .float-menu-item:hover{
            background-color: #00958F;
          }
        }
        a{
          display: inline-block;
          width: 100%;
          color: #333;
          padding: 10px 0px;
        }
        a:hover{
          color: white;
          background-image:  linear-gradient(to right, #02958B, #049554);
        }
        a:hover + .float-menu{
          display: block;
        }
        .float-menu:hover{
          display: block;
        }
      }
    }
  }
  .header-footer-border{
    height: 2px;
    width: 100%;
    background-color: #2E8B57;
  }
}
</style>
