import { render } from "./company.vue?vue&type=template&id=8c48879a"
import script from "./company.vue?vue&type=script&lang=js"
export * from "./company.vue?vue&type=script&lang=js"

import "./company.vue?vue&type=style&index=0&id=8c48879a&lang=scss"
script.render = render
/* custom blocks */
import block0 from "./company.vue?vue&type=custom&index=0&blockType=stylesss&lang=scss&scoped=true"
if (typeof block0 === 'function') block0(script)


export default script