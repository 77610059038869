<template>
  <footer>
    <!--<div class="footer-header">
      <div class="footer-header-container">
        <div class="header-item">
          <van-image
            :src="require('../../assets/zheng.png')"
          />
          <div class="header-item-right">
            <span class="header-item-tx1">
              正品保障
            </span>
            <span class="header-item-tx2">
              正品保障 放心选购
            </span>
          </div>
        </div>
        <div class="header-item">
          <van-image
            :src="require('../../assets/zheng.png')"
          />
          <div class="header-item-right">
            <span class="header-item-tx1">
              正品保障
            </span>
            <span class="header-item-tx2">
              正品保障 放心选购
            </span>
          </div>
        </div>
        <div class="header-item">
          <van-image
            :src="require('../../assets/zheng.png')"
          />
          <div class="header-item-right">
            <span class="header-item-tx1">
              正品保障
            </span>
            <span class="header-item-tx2">
              正品保障 放心选购
            </span>
          </div>
        </div>
        <div class="header-item">
          <van-image
            :src="require('../../assets/zheng.png')"
          />
          <div class="header-item-right">
            <span class="header-item-tx1">
              正品保障
            </span>
            <span class="header-item-tx2">
              正品保障 放心选购
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-mid">
      <div class="footer-mid-item">
        <div class="left">
          <van-image
            :src="require('../../assets/compass.png')"
          />
        </div>
        <div class="right">
          <span class="tx1">配送与支付</span>
          <br>
          <span class="tx2">上门自提</span>
          <br>
          <span class="tx2">支付方式说明</span>
          <br>
          <span class="tx2">配送支付智能查询</span>
        </div>
      </div>
      <div class="footer-mid-item">
        <div class="left">
          <van-image
            :src="require('../../assets/compass.png')"
          />
        </div>
        <div class="right">
          <span class="tx1">配送与支付</span>
          <br>
          <span class="tx2">上门自提</span>
          <br>
          <span class="tx2">支付方式说明</span>
          <br>
          <span class="tx2">配送支付智能查询</span>
        </div>
      </div>
      <div class="footer-mid-item">
        <div class="left">
          <van-image
            :src="require('../../assets/compass.png')"
          />
        </div>
        <div class="right">
          <span class="tx1">配送与支付</span>
          <br>
          <span class="tx2">上门自提</span>
          <br>
          <span class="tx2">支付方式说明</span>
          <br>
          <span class="tx2">配送支付智能查询</span>
        </div>
      </div>
      <div class="footer-mid-item">
        <div class="left">
          <van-image
            :src="require('../../assets/compass.png')"
          />
        </div>
        <div class="right">
          <span class="tx1">配送与支付</span>
          <br>
          <span class="tx2">上门自提</span>
          <br>
          <span class="tx2">支付方式说明</span>
          <br>
          <span class="tx2">配送支付智能查询</span>
        </div>
      </div>
      <div class="footer-mid-item">
        <div class="left">
          <van-image
            :src="require('../../assets/compass.png')"
          />
        </div>
        <div class="right">
          <span class="tx1">配送与支付</span>
          <br>
          <span class="tx2">上门自提</span>
          <br>
          <span class="tx2">支付方式说明</span>
          <br>
          <span class="tx2">配送支付智能查询</span>
        </div>
      </div>
    </div>-->
    <div class="footer-cpro-box">
      <div>
        <!-- spxkz -->
        <a target="_blank" :href="require('../../assets/zyyszcz.jpg')">执业药师证</a> | <a target="_blank" :href="require('../../assets/spxkz.jpg')">食品经营许可证</a> | <a target="_blank" :href="require('../../assets/ypxkz.jpg')">药品经营许可证</a> | <a target="_blank" :href="require('../../assets/yyzz.jpg')">公司营业执照</a> 
      </div>
      <div>
        <a target="_blank" :href="require('../../assets/erleiqixie.jpg')">第二类医疗器械经营备案凭证</a>
      </div>
      <div>
        Copyright © <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021001946号-1</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'CommonFooter'
}
</script>

<style lang="scss" scoped>
footer{
  background: #F3F3F3;
  width: 100%;
  .footer-header{
    .footer-header-container{
      display: flex;
      max-width: 1200px;
      margin: 0 auto;
      padding: 24px;
      border-bottom: 1px #e6e6e6 solid;
      .header-item{
        flex: 1;
        display: flex;
        .header-item-right{
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 8px;
          .header-item-tx1{
            font-size: 18px;
          }
          .header-item-tx2{
            font-size: 12px;
            color: #777777;
            margin-top: 5px;
          }
          // align-items: center;
        }
      }
    }
  }
  .footer-mid{
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    .footer-mid-item{
      display: flex;
      flex: 1;
      .right{
        margin-left: 8px;
        .tx1{
          font-size: 18px;
        }
        .tx2{
          font-size: 14px;
          color: #777777;
          margin-top: 5px;
          line-height: 24px;
        }
      }
    }
  }
  .footer-cpro-box{
    padding: 24px;
    text-align: center;
    font-size: 12px;
    color: #777777;
    line-height: 24px;
    a{
      color: #777777;
    }
  }
}
</style>
