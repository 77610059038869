<template>
  <div class="classify-container">
    <common-header :searchStr="keyword" @search="onSearchClick" />
    <classify-screen
      :brandList="brandList"
      :categoryList="categoryList"
      :brandId="brandId"
      :categoryId="categoryId"
      @categoryChange="onCategory"
      @brandChange="onBrand"
    />
    <classify-goods :goodsList="goodsList" />
    <p-c-pagintion :pageSize="pageSize" :total="total" @currentChange="currentChange" />
    <common-footer />
  </div>
</template>

<script>
import CommonFooter from '@/components/common/CommonFooter.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import ClassifyScreen from '@/components/classify/screen.vue'
import ClassifyGoods from '@/components/classify/goods.vue'
import PCPagintion from '@/components/pc-pagintion/index.vue'
import{ findGoodsList, getCategoryList, getBrandList } from '@/service/global'

export default {
  name: 'Classify',
  components: {
    CommonFooter,
    CommonHeader,
    ClassifyScreen,
    ClassifyGoods,
    PCPagintion
  },
  data () {
    return{
      total: 0,
      pageSize: 12,
      pageNo: 1,
      searchStr: '123',
      goodsList: [],
      brandId: 1,
      categoryId: 26,
      keyword: '',
      brandList: [],
      categoryList: [],
      queryParams: {
        keyword: '',
        brand_id: 0,
        category_id: 0,
      }
    }
  },
  watch: {
    '$route' () {
      this.$router.go(0)
    }
  },
  created () {
    this.init()
  },
  methods: {
    currentChange (value) {
      this.pageNo = value
      // this.queryParams.m = (value === 1) ? 0 : this.pageSize
      this.queryParams.m = (value === 1) ? 0 : ((this.pageSize * this.pageNo) - this.pageSize)
      this.onSearch(this.queryParams)

      // if (value === 1) {
      //   this.queryParams.m = 0
      // }
      // if (this.pageNo)/
    },
    init () {
      const { brand_id, category_id, keyword } = this.$route.query
      this.brandId = parseInt(brand_id)
      this.categoryId = parseInt(category_id)
      this.keyword = keyword
      this.queryParams = {
        keyword: keyword,
        brand_id: brand_id,
        category_id: category_id,
        all: 1,
        n: this.pageSize,
        m: 0
      }
      this.onSearch(this.queryParams)
      this.initScreen()
    },
    initScreen () {
      getCategoryList({ pid: this.categoryId }).then(res => {
        this.categoryList = res
      })
      getBrandList({ category_id: this.categoryId }).then(res => {
        this.brandList = res
      })
    },
    onCategory (value) {
      this.categoryId = value
      this.queryParams.category_id = value
      this.onSearch(this.queryParams)
    },
    onBrand (value) {
      this.brandId = value
      this.queryParams.brand_id = value
      this.onSearch(this.queryParams)
    },
    onSearchClick (value) {
      this.queryParams.keyword = value
      this.onSearch(this.queryParams)
    },
    onSearch (dict) {
      findGoodsList (dict).then(res => {
        this.goodsList = res.data
        this.total = res.total
        console.log(res)
      }).catch(e => {
        console.error(e)
      })
      // alert(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.classify-container{
  display: flex;
  flex-direction: column;
  // height: 100%;
}
</style>