<template>
  <div class="pcindex-container">
    <common-header />
    <div style="flex: 1;">
      <p-c-index-container style="flex: 1" />
    </div>
    <common-footer />
  </div>
</template>

<script>
import CommonFooter from '@/components/common/CommonFooter.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import PCIndexContainer from '@/components/index/index.vue'


export default {
  name: 'PCIndex',
  components: {
    CommonFooter,
    CommonHeader,
    PCIndexContainer
  }
}
</script>

<style lang="scss">
.pcindex-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
