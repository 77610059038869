<template>
  <div class="index-main">
    <ul>
      <li v-for="(floor, index) in floorList" :key="index" class="floor">
        <div class="floor-header">
          <p class="floor-title" :style="{'--bg_color': floor.bg_color}"><span>{{index+1}}F {{floor.name}}</span></p>
          <div class="floor-header-right">
            <a
              v-for="(category,index) in floor.category_list"
              :key="index"
              class="right-link"
              :href="'/#/Classify?category_id='+category.id+'&category_name='+category.name"
            >
              <span v-text="category.name"></span>
              <!-- <span v-text="floor"></span> -->
            </a>
            <!-- <a class="right-link" :href="'/#/Classify?category_id='+floor.id">
              <span>更多></span>
            </a> -->
          </div>
        </div>
        <!-- <p class="floor-title"><span>{{floor.name}}</span>{{floor.vice_name}}</p> -->
        <el-row>
          <el-col class="floor-right" :style="{'border-top':'2px solid ' + floor.bg_color}" :sm="24" :xs="24">
            <el-row>
              <el-col :sm="6" :xs="12" v-for="(good, goodIndex) in floor.goods_list" :key="goodIndex">
                <el-card shadow="hover">
                  <a :href="'/#/detail?id=' + good.id" class="card">
                    <img :src="good.images" class="image">
                    <div style="padding: 14px;">
                      <span class="goods-title">{{good.title}}</span>
                      <div class="price"></div>
                    </div>
                  </a>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </li>
    </ul>
  </div>
</template>

<script>
import 'element-plus/lib/theme-chalk/display.css'
import { getFloorList } from '@/service/global'

export default {
  name: 'recommend',
  data () {
    return{
      floorList: []
      // floorList: [{
      //   id: 1,
      //   pid: 1,
      //   icon: require('../../assets/zheng.png'),
      //   name: "感冒发烧",
      //   vice_name: "有健康才有生活，健康生活的制造者。",
      //   describe: "汇仁发布新品了",
      //   bg_color: "#CD5C5C",
      //   big_images: require('../../assets/20210101215156.png'),
      //   sort: 1,
      //   goods: [{
      //     id: 1,
      //     brand_id: 1,
      //     title: "999感冒灵颗粒",
      //     images: require('../../assets/ganmaolin.png'),
      //     price: 1200
      //   }, {
      //        id: 1,
      //        brand_id: 1,
      //        title: "三精双黄连口服液",
      //        images: require('../../assets/2huanglian.png'),
      //        price: 1200
      //      }, {
      //        id: 1,
      //        brand_id: 1,
      //        title: "康泰克",
      //        images: require('../../assets/kangtaike.png'),
      //        price: 1200
      //      }, {
      //       id: 1,
      //       brand_id: 1,
      //       title: "泰诺(酚麻美敏片)",
      //       images: require('../../assets/tainuo.png'),
      //       price: 1200
      //     }],
      //   items: [{name:"冲剂颗粒"},{name:"药丸胶囊"},{name:"止涕通鼻"},{name:"清热解毒"}],
      //   //config_keywords: ["手机","iphone"]
      // },{
      //           id: 1,
      //           pid: 1,
      //           icon: require('../../assets/zheng.png'),
      //           name: "肠胃不适",
      //           vice_name: "人生吉祥草,健康三素情。",
      //           describe: "汇仁发布新品了",
      //           bg_color: "#D2B48C",
      //           big_images: require('../../assets/20210101220501.png'),
      //           sort: 1,
      //           goods: [{
      //             id: 1,
      //             brand_id: 1,
      //             title: "江中健胃消食片",
      //             images: require('../../assets/jianweixiaoshipian.png'),
      //             price: 1200
      //           }, {
      //                        id: 1,
      //                        brand_id: 1,
      //                        title: "三九胃泰胶囊",
      //                        images: require('../../assets/39weitai.png'),
      //                        price: 1200
      //                      }, {
      //                                    id: 1,
      //                                    brand_id: 1,
      //                                    title: "吗丁啉(多潘立酮片)",
      //                                    images: require('../../assets/madinglin.png'),
      //                                    price: 1200
      //                                  }, {
      //                                                       id: 1,
      //                                                       brand_id: 1,
      //                                                       title: "奥美拉唑肠溶胶囊",
      //                                                       images: require('../../assets/aomeila.png'),
      //                                                       price: 1200
      //                                                     }],
      //           items: [{name:"保健护理"},{name:"保健护理"},{name:"保健护理"},{name:"保健护理"}],
      //           config_keywords: ["手机","iphone"]
      //         },{
      //                   id: 1,
      //                   pid: 1,
      //                   icon: require('../../assets/zheng.png'),
      //                   name: "保健日用",
      //                   vice_name: "人生吉祥草,健康三素情。",
      //                   describe: "汇仁发布新品了",
      //                   bg_color: "#2F4F4F",
      //                   big_images: require('../../assets/20210101211434.png'),
      //                   sort: 1,
      //                   goods: [{
      //                     id: 1,
      //                     brand_id: 1,
      //                     title: "汇仁肾宝片",
      //                     images: require('../../assets/1.png'),
      //                     price: 1200
      //                   }, {
      //                                id: 1,
      //                                brand_id: 1,
      //                                title: "鳕鱼甘油软胶囊",
      //                                images: require('../../assets/yuganyou.png'),
      //                                price: 1200
      //                              }, {
      //                                            id: 1,
      //                                            brand_id: 1,
      //                                            title: "维生素C泡腾片",
      //                                            images: require('../../assets/VC.png'),
      //                                            price: 1200
      //                                          }, {
      //                                           id: 1,
      //                                           brand_id: 1,
      //                                           title: "青源堂 黑芝麻丸",
      //                                           images: require('../../assets/hzmw.png'),
      //                                           price: 1200
      //                                         }],
      //                   items: [{name:"保健护理"},{name:"保健护理"},{name:"保健护理"},{name:"保健护理"}],
      //                   config_keywords: ["手机","iphone"]
      //                 }]
    };
  },
  created () {
    getFloorList().then(res => {
      this.floorList = res
      console.log(res)
    })
  }
}
</script>
<style lang="scss" scoped>
.index-main {
  margin: 0 auto;
  margin-top: 16px;
  max-width: 1200px;
}
.floor {
  margin-bottom: 30px;
  .floor-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .floor-title {
      font-size:12px;
      color: #aaa;
      background-color: #{'var(--bg_color)'};
      padding: 0 25px;
      margin: 0 0 1px 0;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      font-weight: normal;
      span {
        font-size:20px;
        color: white;
        // font-weight: 600;
      }
    }
    .floor-header-right{
      .right-link{
        color: #777;
        font-size: 14px;
        padding: 0 8px;
      }
    }
  }
}

.floor-left {
  padding: 10px;
  color: #fff;
  text-align: center;
  position: relative;
  .floor-left-btn {
      width: 100%;
      overflow: hidden;
      background-color:transparent;
      color: #fff;
      border-color: #fff;
  }
  .floor-left-item {
    margin-bottom: 10px;
  }
  .floor-left-img {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    img {
      width: 100%;
    }
  }
}
.floor-right {
  border-left: 0px;
  padding: 10px;
  a {
    text-decoration: none;
    color: #333;
    .goods-title{
      display: inline-block;
      height: 1.5em;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.image {
  width: 100%;
  display: block;
}
.price {
  color: #FF0000;
  font-weight: 700;
}
.card {
  display: block;
}
</style>