<template>
  <div class="classify-goods">
    <!-- <p>共筛选出 <span class="screen-size">{{size}}</span> 条关于“{{ searchStr }}”的数据</p> -->
    <ul>
      <li class="screen-category">
        <el-row v-if="isParent">
          <el-col :span="24">
            <div class="parent-category">
              <div class="category-name">
                <span v-text="categoryParentName"></span>
              </div>
              <div class="bottom-border">
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="isParent">
          <el-col :span="3" class="screen-left">分类</el-col>
          <el-col :span="21" class="screen-right">
            <el-radio-group v-model="check.category" size="small" @change="onCategoryChange">
              <el-radio-button :label="allCategoryValue">不限</el-radio-button>
              <el-radio-button
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.id"
                class="radio-item"
              >
                {{ item.name }}
              </el-radio-button>
              <!-- <el-radio-button label="2">北京</el-radio-button>
              <el-radio-button label="3">广州</el-radio-button>
              <el-radio-button label="4">深圳</el-radio-button> -->
            </el-radio-group>
          </el-col>
        </el-row>
      </li>
      <li class="screen-brand">
        <el-row>
          <el-col :span="3" class="screen-left">品牌</el-col>
          <el-col :span="21" class="screen-right">
            <el-radio-group v-model="check.brand" size="small" @change="onBrandChange">
              <el-radio-button :label="0">不限</el-radio-button>
              <el-radio-button
                v-for="(item, index) in brandList"
                :key="index"
                :label="item.id"
                class="radio-item"
              >
                {{ item.name }}
              </el-radio-button>
              <!-- <el-radio-button label="3">广州</el-radio-button>
              <el-radio-button label="4">深圳</el-radio-button> -->
            </el-radio-group>
          </el-col>
        </el-row>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ClassifyScreen',
  props: {
    brandId: {
      type: Number,
      default: 0
    },
    categoryId: {
      type: Number,
      default: 0
    },
    brandList: {
      type: Array,
      default: () => {
        return []
      }
    },
    categoryList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return{
      allCategoryValue: 0,
      isParent: false,
      categoryParentName: '',
      size: 100,
      searchStr: '感冒灵',
      check: {
        brand: 0,
        category: 0
      }
    };
  },
  created () {
    this.check.brand = (!this.brandId) ? 0 : this.brandId
    this.check.category = (!this.categoryId) ? 0 : this.categoryId
    this.categoryParentName = this.$route.query.category_name
    this.allCategoryValue = this.categoryId
    if (this.categoryParentName === '' || this.categoryParentName === undefined) {
      this.isParent = false
      this.$emit('brandChange', 0)
      this.$emit('categoryChange', 0)
      return
    }
    this.isParent = true
  },
  methods: {
    onCategoryChange (value) {
      this.$emit('categoryChange', parseInt(value))
    },
    onBrandChange (value) {
      this.$emit('brandChange', parseInt(value))
      // this.$emit('brandChange', value)
    }
  }
}
</script>


<style lang="scss">

.el-radio-button__orig-radio:checked + .el-radio-button__inner{
  background-color: #008f88 !important;
  border-color: #008f88 !important;
}
</style>

<style lang="scss" scoped>
.radio-item{
  margin-bottom: 1rem;
  border-left: 1px solid #DCDFE6;
}
.classify-goods{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  // border: 1px solid #ccc;
  padding: 10px;
  .parent-category{
    width: 100%;
    .category-name{
      padding: .5rem 2rem;
      font-size: 24px;
      background: #008f88;
      color: white;
      display: inline-block;
    }
    .bottom-border{
      border-bottom: 2px solid #008f88;
    }
  }
  p{
    margin: 0;
    line-height: 30px;
    font-size: 12px;
    color: rgba(51,51,51,0.5);
  }
  ul li{
    padding: .5rem 0rem;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 5px;
  }
  ul li:last-child {
    border-bottom: 1px solid #ccc;
  }
  .screen-size{
    color: red;
  }
}
.el-radio-button__inner{
  border: 0;
}
.screen-left{
  font-size: 14px;
  text-align: center;
  line-height: 50px
}
.screen-right{
  padding: .5rem;
  line-height: 50px
}
</style>