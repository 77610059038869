import { createWebHistory } from 'vue-router'
// import mobileRouter from './mobile.js'
import pcRouter from './pc.js'

const routerHistory = createWebHistory()
let router = []
router = pcRouter(routerHistory)
// if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
//   router = mobileRouter(routerHistory)
// } else {
//   router = pcRouter(routerHistory)
// }

export default router