<template>
  <div class="classify-screen">
    <el-row :gutter="20">
      <el-col :sm="6" :xs="12" class="classify-goods-item" v-for="(goods, index) in goodsList" :key="index" @click="toDetail(goods)">
        <el-card shadow="hover" style="flex:1;">
          <div class="card">
            <img :src="'/public/' + goods.images" class="image">
            <div class="card-info">
              <el-tag
                type='success'
                effect="dark"
                class="tags-item"
                size="small"
              >
                自营
              </el-tag>
              <span class="goods-title">{{goods.title}}</span>
              <!-- <el-button class="detail-button" type='primary'>
                查看详情
              </el-button> -->
              <div class="info-buy-way">
                <!-- <div class="buy-way-item">
                  <el-button class="jd-button">
                    京东购买
                  </el-button>
                </div>
                <div class="buy-way-item">
                  <el-button class="tmall-button">
                    天猫购买
                  </el-button>
                </div>
                <div class="buy-way-item">
                  <el-button class="taobao-button">
                    淘宝购买
                  </el-button>
                </div> -->
              </div>
              <div class="price"></div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ClassifyScreen',
  props: {
    goodsList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return{
    // goodsList: [{
    //   id: 1,
    //   brand_id: 1,
    //   title: "999感冒灵颗粒",
    //   images: require('../../assets/ganmaolin.png'),
    //   price: 1200
    // }, {
    //       id: 1,
    //       brand_id: 1,
    //       title: "三精双黄连口服液",
    //       images: require('../../assets/2huanglian.png'),
    //       price: 1200
    //     }, {
    //       id: 1,
    //       brand_id: 1,
    //       title: "康泰克",
    //       images: require('../../assets/kangtaike.png'),
    //       price: 1200
    //     }, {
    //     id: 1,
    //     brand_id: 1,
    //     title: "泰诺(酚麻美敏片)",
    //     images: require('../../assets/tainuo.png'),
    //     price: 1200
    //   },
    //   {
    //     id: 1,
    //     brand_id: 1,
    //     title: "泰诺(酚麻美敏片)",
    //     images: require('../../assets/tainuo.png'),
    //     price: 1200
    //   }]
    }
  },
  created () {
    // console.log(this.$route.query)
  },
  methods: {
    toDetail (item) {
      this.$router.push({
        path: '/Detail',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.classify-screen{
  // border-left: 100px solid #409EFF;
  // border-right: 100px solid #409EFF;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  height: 100%;
  .classify-goods-item{
    margin-bottom: 10px;
    height: 300px;
    max-height: 300px;
    display: flex !important;
    flex-direction: column;
    // margin: 20px 20px 0px 0px;
  }
  img{
    width: 100%;
  }
  .card{
    color: #333;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    .image{
      // flex: 1;
    }
    .card span{
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow:ellipsis;
    }
    .card-info{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .tags-item{
        margin-bottom: .5rem;
      }
      .detail-button{
        margin-top: .5rem;
      }
      .goods-title{
        display: inline-block;
        height: 1.5em;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
      }
      .info-buy-way{
        width: 100%;
        display: flex;
        justify-content: center;
        .buy-way-item{
          flex: 1;
          margin: 0 .5rem;
        }
      }
    }
  }
  
}
</style>