import QS from 'qs'
import axios from 'axios'

// const baseUri = "http://121.4.87.214/public/index.php?s="
const baseUri = "/public/index.php?s="

axios.defaults.baseURL = baseUri
// 20s
axios.defaults.timeout = 20000

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// axios

axios.interceptors.response.use(
  respone => {
    if (respone.status >= 200 && respone.status < 300) {
      return Promise.resolve(respone)
    }
    return Promise.reject(respone)
  },
  error => {
    // error.respone.status
    console.log(error)
  }
)

export const get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export const post = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params)).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}