<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
.el-card{
  height: 100%;
  .el-card__body{
    height: 100%;
    .card{
      height: 100%;
      display: flex;
      flex-direction: column;
      img{
        width: 100%;
        height: 200px;
      }
    }
  }
}
.goods-title{
  text-align: center;
}
@import url('./App.scss');
*{
  box-sizing:border-box;
  -moz-box-sizing:border-box; /* Firefox */
  -webkit-box-sizing:border-box; /* Safari */
}
html{
  padding: 0;
  margin: 0;
  height: 100%;
}
body{
  padding: 0;
  margin: 0;
  height: 100%;
}
#app{
  height: 100%;
  .app-container{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
