import { createApp } from 'vue'
import App from './App.vue'
import { Image, Icon, Swipe, SwipeItem } from 'vant'
import router from './router/index'
import 'vant/lib/index.css'


// PC适配
import '@vant/touch-emulator'

//element-ui
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import './element-variables.scss'




const app = createApp(App)
app.use(ElementPlus)
app.use(Image)
app.use(Icon)
app.use(Swipe)
app.use(SwipeItem)
app.use(router)
app.mount('#app')
window.router = router

// app.use(Button)
