<template>
  <div class="pc-pagintion">
    <el-pagination
      background
      layout="prev, pager, next, jumper"
      :current-page="pageNo"
      @current-change="currentChange"
      :page-size="pageSize"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'PCPagintion',
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageNo: {
      type: Number,
      default: 1
    }
  },
  methods: {
    currentChange (value) {
      this.$emit('currentChange', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.pc-pagintion{
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
