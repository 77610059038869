<template>
  <div class="goods-container">
    <div>
      <div class="common-header-box">
        <common-header />
      </div>
      <div class="detail-header-box">
        <div class="back-box" @click="toHome">
          <el-button circle size="mini" type="info">
            <i class="el-icon-back" style="font-size:1rem;"></i>
          </el-button>
        </div>
      </div>
    </div>
    
    <div class="detail-container">
      <div class="goods-info">
        <el-row>
          <el-col :sm="8" :xs="48">
            <div class="info-left">
              <div class="main-pic-box">
                <el-image
                  :src="goods.images"
                  :fit="'scale-down'"
                  style="width:100%;height:100%;"
                  lazy
                >
                </el-image>
              </div>
              <div class="goods-pic-list">
                <el-row>
                  <el-col :sm="6" :xs="4" v-for="(item, index) in goods.photo" :key="index">
                    <div class="pic-item" @click="selectImg(item)">
                      <el-image
                        :src="item.images"
                        lazy
                      >
                      </el-image>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :sm="16" :xs="48">
            <div class="info-right">
              <div class="info-title">
                <span v-text="goods.title">
                  
                </span>
              </div>
              <div class="info-model">
                <span v-text="goods.model">
                  
                </span>
              </div>
              <div class="info-tags">
                <el-tag
                  effect="dark"
                  class="tags-item"
                >
                  瑞葆堂自营
                </el-tag>
                <el-tag
                  type='success'
                  effect="dark"
                  class="tags-item"
                >
                  线下有售
                </el-tag>
              </div>
              <div class="info-data">
                <div class="data-item">
                  <span class="data-label">浏览次数</span>
                  <span class="data-value">{{1000 + parseInt(goods.access_count)}}</span>
                </div>
              </div>
              <el-divider content-position="left" style="color:#666666 !important;">购买方式</el-divider>
              <!-- <el-divider><i class="el-icon-mobile-phone"></i></el-divider> -->
              <div class="info-buy-way">
                <div class="buy-way-item" v-for="(_item, _index) in goods.link_list" :key="_index">
                  <el-button :disable="(!_item.type.is_enable === 1)" class="buyway-button-general" :style="{'background-color': _item.type.bg_color}" @click="goBuy(_item)">
                    <span v-text="_item.type.name"></span>
                  </el-button>
                </div>
                <!-- <div class="buy-way-item">
                  <el-button class="tmall-button">
                    天猫购买
                  </el-button>
                </div>
                <div class="buy-way-item">
                  <el-button class="taobao-button">
                    淘宝购买
                  </el-button>
                </div> -->
              </div>

              <div class="buy-tips">
                <h5>
                  <i class="el-icon-phone"></i>
                  瑞葆堂客服电话：
                  13868723138
                </h5>
                <h5>
                  <i class="el-icon-warning"></i>
                  请在医师的指导下购买
                </h5>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="goods-detail">
        <el-row style="flex:1;max-width:100%;">
          <el-col :xs="0" :sm="4">
            <div class="left">
              <div class="left-header">
                <span>
                  猜你喜欢
                </span>
              </div>
              <div class="left-main">
                <div class="guess-like">
                  <div class="like-item" v-for="(item, index) in guessList" :key="index" @click="toGoods(item)">
                    <div class="guess-img">
                      <el-image
                        :src="item.images"
                        :fit="'scale-down'"
                        style="width:100%;height:100%;"
                        lazy
                      >
                      </el-image>
                    </div>
                    <div class="guess-title">
                      <a :href="item.href" target="_blank">
                        <span v-text="item.title"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :xs="48" :sm="20" style="max-width:100%">
            <div class="right">
              <div class="right-header">
                <div class="header-item header-item-active">
                  <span>
                    详情
                  </span>
                </div>
              </div>
              <div class="right-main">
                <div v-html="goods.content_web" style="max-width:100%;">
                  
                </div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :xs="48" :sm="0">
            <div class="left" style="margin-right: 0px !important;">
              <div class="left-header">
                <span>
                  猜你喜欢
                </span>
              </div>
              <div class="left-main">
                <div class="guess-like">
                  <el-row>
                    <el-col :xs="12" v-for="(item, index) in guessList" :key="index">
                      <div class="like-item" @click="toGoods(item)">
                        <div class="guess-img">
                          <el-image
                            :src="item.images"
                            :fit="'scale-down'"
                            style="width:100%;height:100%;"
                            lazy
                          >
                          </el-image>
                        </div>
                        <div class="guess-title">
                          <a :href="item.href" target="_blank">
                            <span v-text="item.title"></span>
                          </a>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-col> -->
        </el-row>
      </div>
    </div>
    <common-footer />
  </div>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import { getGoodsById, getGoodsLike } from '@/service/global'

export default {
  name: 'GoodsDetail',
  data () {
    return {
      id: -1,
      mainPic: require('@/assets/2huanglian.png'),
      goods: {},
      picList: [
        {
          id: 1,
          pic: require('@/assets/2huanglian.png')
        },
        {
          id: 1,
          pic: require('@/assets/2huanglian.png')
        },
        {
          id: 1,
          pic: require('@/assets/2huanglian.png')
        },
        {
          id: 1,
          pic: require('@/assets/2huanglian.png')
        }
      ],
      guessList:[
        {
          id: 1,
          pic: require('@/assets/2huanglian.png'),
          name: '双黄连口服液',
          href: 'https://www.jd.com/'
        },
        {
          id: 1,
          pic: require('@/assets/2huanglian.png'),
          name: '双黄连口服液',
          href: 'https://www.jd.com/'
        },
        {
          id: 1,
          pic: require('@/assets/2huanglian.png'),
          name: '双黄连口服液',
          href: 'https://www.jd.com/'
        }
      ]
    }
  },
  watch: {
    '$route' () {
      this.$router.go(0)
    }
  },
  components: {
    CommonFooter,
    CommonHeader
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.id = this.$route.query.id
      getGoodsById(this.id).then(res => {
        this.goods = res
        console.log(res)
      }).catch(e => {
        console.error(e)
      })
      getGoodsLike().then(res => {
        this.guessList = res.slice(0, 5)
      }).catch(e => {
        console.error(e)
      })
    },
    goBuy (item) {
      window.open(item.url, '_blank')
      // window.location.href = item.url
    },
    toHome () {
      window.location.href = '/#/'
    },
    toGoods (item) {
      window.location.href = '/#/detail?id=' + item.id
    },
    selectImg (item) {
      this.goods.images = item.images
    }
  }
}
</script>

<style lang="scss">
.el-divider__text{
  color: #666666 !important;
}
</style>

<style lang="scss">
.goods-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  .common-header-box{
    @media screen and(max-width: 1200px) {
      display: none;
    }
  }
  .detail-header-box{
    position: fixed;
    left: .5rem;
    top: .5rem;
    z-index: 999;
    .back-box{

    }
  }
  .detail-container{
    margin: 0 auto;
    max-width: 1200px;
    padding: 16px 0;
    @media screen and (max-width: 1200px) {
      max-width: 100% !important;
      padding: 0px;
    }
    .goods-info{
      display: flex;
      flex-direction: row;
      .info-left{
        // width: 398px;
        .main-pic-box{
          border: 1px solid #eee;
          // width: 398px;
          height: 398px;
          @media screen and(max-width: 1200px){
            height: 15rem;
          }
        }
        .goods-pic-list{
          margin-top: 8px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .pic-item{
            // width: 73.5px;
            margin-right: 8px;
            margin-top: 8px;
            cursor: pointer;
            border: 1px solid #eee;
            padding: 8px;
          }
        }
      }
      .info-right{
        flex: 1;
        padding: 0px 16px;
        .info-title{
          width: 100%;
          font-weight: 600;
          font-size: 20px;
          @media screen and(max-width: 1200px) {
            font-size: 1rem;
            margin-top: 1rem;
          }
          .sub-title{
            width: 95%;
            font-size: 14px;
            color: #666;
            font-weight: normal;
          }
        }
        .info-model{
          font-size: 14px;
          // color: #FF0036;
        }
        .info-data{
          width: 100%;
          display: flex;
          padding: 16px;
          margin-top: 8px;
          box-sizing: border-box;
          background-image: linear-gradient(to right, #f2fff8 1%, #a7facb);
          .data-item{
            margin-right: 16px;
            display: flex;
            align-items: center;
            .data-label{
              font-size: 12px;
              color:#666;
              margin-right: 8px;
            }
            .data-value{
              color: #C40000;
              font-size: 20px;
            }
          }
        }
        .info-tags{
          width: auto;
          margin-top: 16px;
          .tags-item{
            margin-right: 8px;
          }
        }
        .info-buy-way{
          margin-top: 1rem;
          display: flex;
          flex-flow: wrap;
          .buy-way-item{
            margin-right: 1rem;
            margin-bottom: 1rem;
          }
        }
        .buy-tips{
          color: #666;
        }
      }
    }
    .goods-detail{
      display: flex;
      margin-top: 1rem;
      width: 100%;
      .left{
        // width: 180px;
        margin-right: 1rem;
        .left-header{
          background: #f7f7f7;
          text-align: center;
          box-sizing: border-box;
          border-bottom: 1px solid #01923F;
          padding: .5rem;
        }
        .left-main{
          .guess-like{
            .like-item{
              width: 100%;
              cursor: pointer;
              margin-bottom: 1.5rem;
              padding: .5rem;
              box-sizing: border-box;
              .guess-img{
                border: #f7f7f7 1px solid;
                margin-bottom: .5rem;
              }
              .guess-title{
                width: 100%;
                font-size: 14px;
                color: #666666;
                a{
                  width: 100%;
                  display: inline-block;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .right{
        flex: 1;
        width: 100%;
        .right-header{
          box-sizing: border-box;
          background-color: #f7f7f7;
          display: flex;
          .header-item{
            padding: .5rem 2rem;
            text-align: center;
            flex: 1;
          }
          .header-item-active{
            border-bottom: 1px solid #01923F;
          }
        }
        .right-main{
          display: flex;
          justify-content: center;
          *{
            width: 100%;
          }
          // img{
          //   width: 100%;
          // }
        }
      }
    }
  }
  // @media screen and (max-width: 960px){
  //     body{
  //         background: #000;
  //     }
  // }

}
</style>

