<template>
  <div class="company-container">
    <common-header :searchStr="keyword" @search="onSearchClick" />
    <div class="company-html-container">
      <div v-html="page"></div>
    </div>
    <common-footer />
  </div>
</template>

<script>
import CommonFooter from '@/components/common/CommonFooter.vue'
import CommonHeader from '@/components/common/CommonHeader.vue'
import { getCompany } from '@/service/global'

export default {
  name: 'Company',
  data () {
    return {
      page: ''
    }
  },
  created () {
    getCompany().then(res => {
      console.log(res.page)
      this.page = res.page
    })
  },
  components: {
    CommonHeader,
    CommonFooter
  }
}
</script>

<style lang="scss">
img{
  width: 100%;
}
</style>


<stylesss lang="scss" scoped>
.company-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  .company-html-container{
    max-width: 1200px;
    padding: 2rem 0;
  }
}
</stylesss>
