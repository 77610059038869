<template>
  <div>
    <!-- <van-swipe
      touchable
      class="my-swipe"
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item v-for="(item, index) in bannerList" :key="index">
        <van-image @click="onBannerClick(item)" width="100%" height="100%" fit="fill" :src="item.images_url" />
      </van-swipe-item>
    </van-swipe> -->
    <recommend></recommend>
  </div>
</template>

<script>
import { getBanner } from '@/service/global.js'
import Recommend from './recommend.vue'

export default {
  name: 'index',
  data () {
    return {
      bannerList: []
    }
  },
  components: {
    Recommend
  },
  created () {
    getBanner().then(res => {
      this.bannerList = res
    })
  },
  methods: {
    onBannerClick (item) {
      window.open(item.event_value, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-swipe .van-swipe-item {
  // color: #fff;
  // font-size: 20px;
  height: 480px;
  @media screen and(max-width: 1200px) {
    height: 10rem;
  }
  // line-height: 150px;
  // text-align: center;
  // background-color: #39a9ed;
}
</style>