import { get, post } from '@/utils/request'

export function getBanner () {
  return get('index/index/getBanner')
}

export function getBanner1 () {
  return post('index/index/getBanner')
}

export function getFloorList () {
  return get('index/index/getHomeFloorList')
}

export function getGoodsById (id) {
  return get('index/goods/getGoods/id/' + id)
}

export function getGoodsLike () {
  return get('index/goods/getLikes')
}

export function findGoodsList (params) {
  return get('Index/search/findGoods', params)
}

export function getBrandList (params) {
  return get('Index/search/getBrand', params)
}

export function getCategoryList (params) {
  return get('Index/search/getCategory', params)
}

export function getCompany () {
  return get('Index/introduce/getintroduce')
}
